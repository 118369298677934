* {
  margin: 0;
  -webkit-text-size-adjust: 100%;
}

.App {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 360px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
    url("/public/media/footerv6.jpg");
  background-position: center center;
  background-size: cover;
}
.my__button {
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  font-weight: 700;
  border-radius: 5px;
  padding-left: 2rem;
  padding-right: 2rem;

  margin-top: 30px;
  padding-top: 0.5rem;
  background-color: rgb(51, 51, 51);
  padding-bottom: 0.5rem;
  font-size: 26px;
  font-family: "BenchNine";
  text-transform: uppercase;
  width: 200px;
  transition: all 0.5s;
}
.row1 {
  margin-top: -100px;
}
.page {
  background-color: #131313;
}
@media only screen and (max-width: 949px) {
  .mobile__hide {
    display: none;
    visibility: hidden;
  }
}
@media only screen and (min-width: 950px) {
  .desktop__hide {
    display: none;
    visibility: hidden;
  }
}
.home__page {
  background: #101010;
}
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}
input {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  width: 310px;
  margin: 0px;
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  font-family: "Oxygen";
  color: #000;
  border-radius: 100px;
  border: 1px solid #333;

  box-sizing: border-box;
  margin-top: 03px;
}
.login__button {
  font-family: "BenchNine";
  text-transform: uppercase;
  border-radius: 100px;
  border: 1px #333 solid;
  padding: 15px 0px 15px 0px;
  background: #131313;

  color: #fff;
  font-size: 18px;
}
.login__button:hover {
  font-weight: bold;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 1);
  cursor: pointer;
}
.my__button:hover {
  background-color: rgba(51, 51, 51, 0.8);
}
.buy__now {
  background: #b90000;
}
.buy__now:hover {
  background: #f00000;
}
.buy__now:disabled {
  background-color: #555 !important;
  pointer-events: none;
}
.buy__button__box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
  box-sizing: border-box;
}
.buy__now__button {
  margin: 0px !important;
  width: 310px;
}
.buy___now__button:disabled {
  transition: all 0.5s;
  background: #333 !important;
}
.no__clip__button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.button__usd__price {
  font-size: 18px;
  color: #aaa !important;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding-left: 10px;
  margin-top: 5px;
}
.white__eth__logo {
  filter: brightness(0.5) invert(1);
  margin-top: -5px;
}
.inside__button__div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.button__price {
  display: flex;
  align-items: center;
}
.polygon__logo {
  filter: brightness(0.2) invert(1);
  margin-right: 5px;
}
.polygon__logo__dark {
  filter: brightness(0.5) invert(1);
  margin-right: 5px;
}
.transaction__link {
  color: #fff;
}
.transaction__link:hover {
  color: #aaa;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  background: #fff;
  background-image: none;
  border: solid 1px #000;
  border-radius: 5px;
  color: #000;
  text-align: center;
}

.select {
  position: relative;
  display: block;
  width: 100%;
  height: 3em;
  line-height: 3;
  background: #000000;
  overflow: hidden;
  border-radius: 5px;

  margin-bottom: 05px;
  text-align: center;
  color: #000;
}

select {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 0 0 0.5em;
  cursor: pointer;
  text-align: center;
  font-family: "Oxygen";
  font-size: 16px;
}

select::-ms-expand {
  display: none;
}

.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 1em;
  background: #444;
  pointer-events: none;
}

.select:hover::after {
  color: #b90000;
}

.select::after {
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
.marketplace__icons__div {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.marketplace__icon__div {
  display: flex;
  width: 50px;
  padding: 10px;
  height: 40px;
  filter: brightness(1);
  filter: opacity(0.6);
  cursor: pointer;
}
.marketplace__icon__div:hover {
  transition: all 0.5s;
  filter: brightness(0.8);
}
.invert__icon {
  filter: invert(1);
}
.disabled__grey:disabled {
  background: #777;
  color: #ccc;
  pointer-events: none;
}
.img__replacement {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 200px;
  padding-bottom: 20px;
  background: none;
}
.row__center {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none !important;
}

.no__user {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 320px;
  margin-top: 25px;
  font-family: "Saira";
  font-size: 20px;
}
.no__user h3 {
  font-size: 24px;
}
.wave {
  width: 5px;
  height: 20px;
  background: #fff;
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}
@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.play__now__button {
  background: rgb(27, 172, 14);
}
.play__now__button:hover {
  background: rgb(26, 222, 8);
}
.play__now__button:disabled {
  background: #000;
  filter: opacity(0.5);
  cursor: default;
  transition: all 0.5s;
}
.play__now__button:disabled:hover {
  background: #101010;
}
.play__now__button:disabled:hover::after {
  content: "Own to Unlock";
}
.play__now__button:disabled:hover .play__inner {
  display: none;
}

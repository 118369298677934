.email__disclaimer {
  padding-top: 10px;
}
.help__heading {
  padding-top: 25px;
}
.reset__password__div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 350px;
  padding-top: 10px;
}
.reset__input__box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 160px;
}

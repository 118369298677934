.minting__now__container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  background: #101010;
}
.minting__now__title__div {
  background: url("/public/media/minting-bkg.jpg");
  background-size: cover;
  background-position: center right;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 800px;
}
.minting__now__top__fade {
  height: 200px;
  width: 100%;
  background: linear-gradient(180deg, #101010 20%, #10101000 100%);
}
.minting__now__bottom__fade {
  width: 100%;
  height: 200px;
  background: linear-gradient(#10101000 0%, #101010 80%);
}
.minting__now__top__title {
  font-family: "Space Grotesk";
  font-size: 70px;
  margin-left: -200px;
  font-weight: 300;
}
.minting__now__bottom__title {
  font-family: "Space Grotesk";
  font-size: 100px;
  font-weight: 300;
}
.minting__now__title__emp {
  font-weight: 700;
}
@media screen and (min-width: 801px) and (max-width: 1200px) {
  .minting__now__top__title {
    font-size: 50px;
    margin-left: -150px;
  }
  .minting__now__bottom__title {
    font-size: 70px;
  }
}
@media screen and (max-width: 800px) {
  .minting__now__top__title {
    font-size: 40px;
    margin-left: 0px;
    padding-left: 10px;
  }
  .minting__now__bottom__title {
    font-size: 55px;
    margin-left: 30px;
    padding-right: 10px;
    white-space: nowrap;
  }
}
.row {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -200px;
  padding-bottom: 40px;
}
.row__posters {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  align-items: center;

  flex-wrap: wrap;
}
.marketplace__button__div {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.row__posters::-webkit-scrollbar {
  display: none;
}

.row__poster {
  margin-top: 20px;
  object-fit: contain;
  width: 280px;
  max-width: 45%;
  margin-right: 10px;
  margin-left: 10px;
  transition: transform 450ms;
  border: solid 1px #ccc;
  background: #333;
  border-radius: 20px;
  cursor: pointer;
  box-sizing: border-box;
}

.row__poster:hover {
  transform: scale(1.05);
}

.row__posterLarge {
  max-height: 500px;
}

.row__posterLarge:hover {
  transform: scale(1.07);
}
.video__player {
  transition: all 450ms;
}

.click__preview {
  position: fixed;
  top: 120px;
  text-align: center;
  background: #000;
  width: 90%;
  z-index: 10;
  margin-bottom: 10px;
}
.preview__title {
  padding-top: 20px;
  padding-bottom: 5px;
  font-size: 30px;
  padding-bottom: 10px;
}
.performance__info {
  padding-bottom: 20px;
}

.row__title {
  margin-left: 40px;
}

.row__col1 {
  width: 600px;
  vertical-align: center;
}
.row__col2 {
  flex: 100%;
}

.row1 {
  padding-top: 30px;
}
.row1 .row__title {
  font-size: 20px;
  font-weight: bold;
}
.description {
  text-align: left;
  margin-left: 30px;
  padding-bottom: 20px;
  width: 600px;
}

.blow__up {
  font-size: 30px;
}
.thirds {
  min-width: 200px;
  text-align: center;
  padding-top: 20px;
}
.halfs {
  min-width: 300px;
}
.smol__halfs {
  min-width: 250px;
}
.price {
  justify-content: flex-end;
}
.price__p {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}
.price.p {
  font-size: 26px;
}
.buttons__box {
  align-items: center;
  align-content: center;
  padding-bottom: 30px;
}
.converted__currency {
  color: #777;
}
.three__col {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.emph {
  font-size: 20px;
  font-weight: bold;
}
.two__col {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
.promo__h3 {
  font-size: 20px;
  padding-bottom: 30px;
}
.preview__buttons__box {
  display: flex;
  justify-content: space-evenly;
}
.preview__buttons__div {
  width: 700px;
  display: flex;
  justify-content: space-evenly;
}

.row__media__player {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #000000;
  min-height: 200px;
  max-height: 40vh;
  justify-content: center;
  object-fit: scale-down;
  box-sizing: border-box;
}
.row__media__player video {
  max-height: 40vh;
}
.no__media__player__row {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(#bbb, #333);
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.token__div__container {
  z-index: 5;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #b90000;
  color: #b90000;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #b90000;
  color: #b90000;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #b90000;
  color: #b90000;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

.no__promo__clip__token {
  max-width: 200px;
  padding-bottom: 20px;
  cursor: pointer;
}
.no__promo__clip__token:hover {
  transition: all 0.5s;
  transform: scale(1.05);
}
@keyframes dotFlashing {
  0% {
    background-color: #b90000;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
.remaining {
  display: flex;
  justify-content: center;
}
.dots__div {
  padding-left: 25px;
  margin-top: 15px;
  padding-right: 25px;
  margin-left: 10px;
  padding-bottom: 10px;
}
.bump__right {
  margin-left: 10px;
}
.quantity__p {
  display: flex;
  align-items: flex-end;
}
.quantity__p__result {
  display: flex;
}
.release__date__div {
  font-size: 20px;
  padding-bottom: 10px;
  font-weight: bold;
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.release__date__highlight {
  background: #555;
  max-width: 500px;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  border: solid #fff 4px;
  box-sizing: border-box;
}
.row__scroll__div {
  z-index: 5;
  min-height: 100px;
  height: 100px;
  min-width: 80px;
  margin-right: 5px;
  right: 0px;

  border-radius: 25px;
  color: #00000055;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  cursor: pointer;
}
.scroll__right__icon {
  font-size: 50px;
}
.row__scroll__div__container {
  z-index: 5;
  min-height: 100px;
  height: 200px;
  min-width: 120px;
  position: absolute;

  margin-top: 150px;
  border-radius: 25px;
  color: #00000055;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.row__scroll__div__container:hover .row__scroll__div {
  background: #cccccc74;
  color: #00000099;
}
.right__row__scroll__container {
  right: 0px;
}
.left__row__scroll__container {
  left: 0px;
  margin-left: -40px;
  padding-left: 10px;
}

.mint__progress__bar {
  width: 100%;
  padding-top: 20px;
}
.mint__progress {
  width: 500px;
  min-height: 30px;
  -webkit-appearance: none;
  appearance: none;
}
.mint__progress::-webkit-progress-bar {
  background: #333;
  border-radius: 10px;
  margin-bottom: -10px;
}
@keyframes aliveBar {
  0% {
    background: linear-gradient(90deg, #b90000, #f00000);
  }

  100% {
    background: linear-gradient(90deg, #f00000, #000000);
  }
}
.mint__progress::-webkit-progress-value {
  background: linear-gradient(90deg, #b90000, #f00000);
  border-radius: 10px;
  animation: aliveBar 2s ease-in-out;
}

@media screen and (min-width: 1050px) {
  .row__scroll__div__container {
    display: none;
  }
}

.mint__progress__bar__container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
}
.mint__progress__bar__div {
  height: 30px;
  width: 500px;
  max-width: 90%;
  background: #333;
  border-radius: 10px;
  display: flex;
  position: relative;
  overflow: hidden;
}
@keyframes aliveBar {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.mint__progress__bar__inner {
  background: linear-gradient(90deg, #b90000, #f00000);
  border-radius: 10px;
  transition: all 1s;
  animation: aliveBar;
  animation-duration: 2s;
  -webkit-animation: aliveBar 2s ease-in-out infinite;
}

.top__p {
  display: flex;
  align-items: baseline;
}
.venue__p {
  justify-content: flex-end;
}
.bump__down {
  padding-left: 5px;
}
@media only screen and (max-width: 700px) {
  .thirds {
    min-width: 0px;
    padding-left: 10px;
    padding-right: 10px;
    border-right: solid 2px #333;
    padding-top: 5px;
    margin-top: 0px;
  }
  .top__p {
    flex-direction: column;
    align-items: center;
  }
  .halfs {
    min-width: 0px;
    border-right: 2px solid #333;
    border-bottom: 2px solid #333;
    padding: 10px 10px 10px 10px;
  }
  .price {
    border-right: none;
    padding-bottom: 10px;
  }
  .venue {
    border-right: none;
  }
  .preview__button {
    padding-left: 5px;
    padding-right: 5px;
    margin: 5px;
    border-radius: 5px;
  }
  .preview__buttons__box {
    display: flex;
    justify-content: space-evenly;
    padding-top: 10px;
  }
  .preview__buttons__div {
    width: 90%;
    justify-content: space-between;
    padding-top: 10px;
  }
  .two__col {
    padding-top: 0px;
  }
  .split__col {
    flex-direction: column;
  }
  .concert__info__div {
    align-items: center;
  }
  .token__div__container {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
  .listing__buttons__div {
    display: flex;
    width: 300px;
    justify-content: space-evenly;
  }
  .listing__buttons__div button {
    width: 60px;
    height: 40px;
    font-size: 20px;
  }
  .remaining {
    flex-direction: column;
    padding-bottom: 10px;
  }
  .preview__title {
    font-size: 24px;
  }
  .emph {
    font-size: 16px;
    font-weight: bold;
  }
  .blow__up {
    font-size: 20px;
  }
  .halfs {
    width: 50%;
  }
  .three__col {
    justify-content: space-between;
  }
  .quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
  }
  .three__col {
    padding-top: 0px;
  }
  .remaining {
    width: 25%;
  }
  .price {
    max-width: 50%;
  }
  .row__end__spacer {
    min-width: 10px;
  }
  .quantity__p {
    flex-direction: column;
  }
  .dots__div {
    margin-top: 05px;
  }
  .quantity__p__result {
    padding-top: 10px;
  }
  .bump__down {
    padding-top: 10px;
  }
  .price__p {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (min-width: 910px) and (max-width: 1240px) {
  .row__poster {
    width: 400px;
    margin: 20px;
  }
}
@media screen and (max-width: 400px) {
  .row__poster {
    width: 40%;
  }
}
